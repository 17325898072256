<template>
  <p class="text-base font-medium text-red-800">
    <span v-if="resultsStore.videoInfo?.duration > 0"
      >The requested video is
      {{ formatDurationUsage(resultsStore.videoInfo?.duration) }}, while</span
    ><span>
      you have
      {{ formatDurationUsage(authStore.user?.remaining_credit) }} summarization
      minutes remaining in your account.</span
    >
    <br />
    <a
      @click="handleUpgradeNowClick"
      class="underline font-bold text-brand-600 cursor-pointer"
    >
      Upgrade for just $0.75
    </a>
    and keep enjoying our service with pay-as-you-go flexibility. No
    subscription!
  </p>
</template>

<script setup>
import { useRouter } from "vue-router";
import { usePaymentStore } from "@/stores/payment/paymentStore";
import { useSummarizerResultsStore } from "@/stores/summarizer/summarizerResultsStore";
import { useAuthStore } from "@/stores/authStore";
import { formatDurationUsage } from "@/utils";

const resultsStore = useSummarizerResultsStore();
const payemntStore = usePaymentStore();
const router = useRouter();
const authStore = useAuthStore();

function handleUpgradeNowClick() {
  payemntStore.setShowPricingInProfile(true);
  router.push({ name: "Profile" });
}
</script>
