import { ref, computed, watch } from "vue";
import axios from "axios";
import { useRouter } from "vue-router";
import { defineStore } from "pinia";
import { useEventSource } from "@vueuse/core";
import { useSummarizerFormStore } from "@/stores/summarizer/summarizerFormStore";
import { useSummarizerStatusStore } from "@/stores/summarizer/summarizerStatusStore";
import { useAuthStore } from "@/stores/authStore";
import { useAlertStore } from "@/stores/alertStore";
import {
  END_OF_STREAM,
  INVALID_URL,
  NO_VIDEO_FOUND,
  ERROR_PROCESSING_VIDEO,
  PROGRESS_UPDATE,
  USAGE_EXCEEDED,
  VIDEO_INFO,
  SUMMARY_SLUG,
  CHAPTER_INFO,
  END_OF_CHAPTER,
  ALERT_TYPE_INFO,
  ALERT_TYPE_ERROR,
} from "@/constants";

export const useSummarizerResultsStore = defineStore(
  "summarizerResults",
  () => {
    const router = useRouter();
    const formStore = useSummarizerFormStore();
    const statusStore = useSummarizerStatusStore();
    const authStore = useAuthStore();
    const alertStore = useAlertStore();

    const initialVideoState = {
      title: "",
      thumbnail_url: "",
      website: "",
      url: null,
      duration: 0,
      streamId: null,
      chapters: [],
    };

    const videoInfo = ref({ ...initialVideoState, chapters: [] });
    const chapters = computed(() => videoInfo.value?.chapters || []);

    const initialSummaryState = {
      slug: null,
      fullSummary: [],
      isPublic: false,
    };

    const summaryInfo = ref({ ...initialSummaryState, fullSummary: [] });
    const currentChapterIndex = ref(0);

    const closeEventSource = ref(undefined);

    const initializeState = () => {
      videoInfo.value = { ...initialVideoState, chapters: [] };
      summaryInfo.value = { ...initialSummaryState, fullSummary: [] };
      currentChapterIndex.value = 0;
    };

    const setVideoInfo = (newVideoInfo) => {
      videoInfo.value = { ...videoInfo.value, ...newVideoInfo };
    };

    const handleResponseType = ({ responseType, parsedData, close }) => {
      switch (responseType) {
        case END_OF_STREAM:
          if (parsedData.stream_id && videoInfo.value) {
            videoInfo.value.streamId = parsedData.stream_id;
          }

          close();
          formStore.setUploadingFile(false);
          if (statusStore.connectionStatusType !== ALERT_TYPE_ERROR) {
            statusStore.initializeState();
          } else {
            statusStore.setIsResultLoading(false);
          }
          break;
        case VIDEO_INFO:
          setVideoInfo(parsedData);
          break;
        case SUMMARY_SLUG:
          summaryInfo.value.slug = parsedData.summary_slug;
          router.replace({
            name: "SummarizationItem",
            params: { url: summaryInfo.value.slug },
          });
          break;
        case CHAPTER_INFO:
          videoInfo.value.chapters = parsedData.chapters;
          break;
        case PROGRESS_UPDATE:
          statusStore.setConnectionStatusMessage(parsedData.message);
          statusStore.setConnectionStatusType(ALERT_TYPE_INFO);
          break;
        case NO_VIDEO_FOUND:
          statusStore.setConnectionStatusMessage(parsedData.message);
          statusStore.setConnectionStatusType(ALERT_TYPE_ERROR);
          statusStore.setIsResultLoading(false);
          videoInfo.value = { ...initialVideoState, chapters: [] };
          break;
        case ERROR_PROCESSING_VIDEO:
          statusStore.setConnectionStatusMessage(parsedData.message);
          statusStore.setConnectionStatusType(ALERT_TYPE_ERROR);
          statusStore.setIsResultLoading(false);
          videoInfo.value = { ...initialVideoState, chapters: [] };
          break;
        case USAGE_EXCEEDED:
          statusStore.setConnectionStatusMessage(parsedData.message);
          statusStore.setConnectionStatusType(ALERT_TYPE_ERROR);
          statusStore.setConnectionErrorAction(parsedData.action);
          videoInfo.value.duration = parsedData.data.video_duration;
          authStore.setUserRemainingCredit(parsedData.data.remaining_credit);
          break;
        case INVALID_URL:
          statusStore.setConnectionStatusMessage(parsedData.message);
          statusStore.setConnectionStatusType(ALERT_TYPE_ERROR);
          videoInfo.value = { ...initialVideoState, chapters: [] };
          break;
      }
    };

    const streamGeneratedSummary = (newData) => {
      if (
        chapters.value.length > 0 &&
        currentChapterIndex.value < chapters.value.length
      ) {
        if (newData === END_OF_CHAPTER) {
          currentChapterIndex.value++; // Move to the next chapter
          return;
        }

        const currentChapter = chapters.value[currentChapterIndex.value];
        if (!currentChapter.summary) {
          currentChapter.summary = [];
        }
        currentChapter.summary.push(newData);
        return;
      }
      summaryInfo.value.fullSummary.push(newData);
    };

    const handleURLSubmit = async () => {
      if (statusStore.isResultsLoading) {
        return;
      }
      if (!authStore.user.is_authenticated) {
        alertStore.showError("Please login to summarize a file.");
        router.push({ name: "Login" });
        return;
      }
      initializeState();
      statusStore.initializeState();
      statusStore.setIsResultLoading(true);
      statusStore.setIsFetchingExistingSummary(false);
      if (formStore.uploadingFile) {
        formStore.setVideoUrl("");
      }

      const endpointUrl = `/api/summaries/stream?video_url=${encodeURIComponent(
        formStore.uploadingFile ? formStore.objectName : formStore.videoUrl
      )}&uploaded_file=${formStore.uploadingFile}&file_name=${
        formStore.fileNameToUpload
      }`;

      const { status, data, error, close } = useEventSource(endpointUrl);
      statusStore.setConnectionStatus(status);
      closeEventSource.value = close;

      watch(error, () => {
        statusStore.setConnectionStatusMessage(
          "There was a connection error. Please try again later."
        );
        statusStore.setConnectionStatusType(ALERT_TYPE_ERROR);
        initializeState();
        formStore.initializeState();
        statusStore.setIsResultLoading(false);
        close();
        return;
      });

      watch(data, (newData) => {
        if (newData) {
          try {
            const parsedData = JSON.parse(newData);
            if (
              typeof parsedData !== "object" ||
              parsedData === null ||
              Array.isArray(parsedData) // when data is a single number
            ) {
              throw new Error("not_an_object");
            }
            const responseType = parsedData.response_type;
            handleResponseType({ responseType, parsedData, close });
          } catch (error) {
            if (
              error instanceof SyntaxError ||
              error.message === "not_an_object"
            ) {
              streamGeneratedSummary(newData);
            } else {
              console.log(error);
            }
          }
        }
      });
    };

    const fetchExistingSummary = async (summarySlug) => {
      initializeState();
      formStore.setVideoUrl("");
      const options = {
        url: `/api/summaries/get-summary/${summarySlug}`,
        method: "GET",
      };
      if (sessionStorage.getItem("titleUpdated")) {
        options.headers = {
          "Cache-Control": "no-cache",
        };
        sessionStorage.removeItem("titleUpdated");
      }

      axios(options)
        .then((res) => {
          const summaryData = res.data.Summary;
          const videoData = res.data.Video;
          const streamData = res.data.Stream;
          summaryInfo.value.fullSummary.push(summaryData.full_summary);
          summaryInfo.value.isPublic = summaryData.is_public;
          setVideoInfo(videoData);
          videoInfo.value.chapters = summaryData.chapters;
          videoInfo.value.streamId = streamData.id;
          videoInfo.value.isTitleEditable = videoData.is_title_editable;
          summaryInfo.value.slug = summarySlug;
        })
        .catch(() => {});
    };

    const fetchAudio = async (summarySlug) => {
      const options = {
        url: `/api/summaries/listen/${summarySlug}`,
        method: "GET",
        responseType: "blob",
      };

      return axios(options);
    };

    const shareSummary = async () => {
      const options = {
        url: `/api/summaries/share-summary`,
        method: "POST",
        data: { summary_slug: summaryInfo.value.slug, is_public: true },
      };

      return axios(options).then(() =>
        navigator.clipboard
          .writeText(
            process.env.VUE_APP_BASE_URL + router.currentRoute.value.fullPath
          )
          .then(() => {
            summaryInfo.value.isPublic = true;
            alertStore.showSuccess("URL copied to clipboard.");
          })
      );
    };

    const updateStream = async (streamUpdateData) => {
      const options = {
        url: `/api/streams/${videoInfo.value.streamId}`,
        method: "PUT",
        data: streamUpdateData,
      };
      return axios(options);
    };

    const updateTitle = async (title, onUpdated) => {
      const options = {
        url: `/api/videos/update-title/${videoInfo.value.id}`,
        method: "PUT",
        data: { title },
      };
      return axios(options)
        .then(({ data }) => {
          videoInfo.value.title = data.title;
          onUpdated();
          sessionStorage.setItem("titleUpdated", true);
        })
        .catch((error) => {
          alertStore.showError(error.response.data.detail);
          onUpdated();
        });
    };

    return {
      videoInfo,
      chapters,
      summaryInfo,
      closeEventSource,
      setVideoInfo,
      handleURLSubmit,
      fetchExistingSummary,
      initializeState,
      fetchAudio,
      shareSummary,
      updateStream,
      updateTitle,
    };
  }
);
