<template>
  <div title="Copy">
    <ClipboardDocumentIcon
      v-if="!showClipboardCheckIcon"
      class="h-6 w-6 cursor-pointer"
      @click="copyText(formatTextForCopy(props.text))"
    />
    <ClipboardDocumentCheckIcon v-else class="h-6 w-6 text-brand-800" />
  </div>
</template>
<script setup>
import { ref } from "vue";
import { useSummarizerResultsStore } from "@/stores/summarizer/summarizerResultsStore";

import {
  ClipboardDocumentIcon,
  ClipboardDocumentCheckIcon,
} from "@heroicons/vue/24/outline";

const resultsStore = useSummarizerResultsStore();

const showClipboardCheckIcon = ref(false);

const formatTextForCopy = (text) => {
  return text.replace(/<new-line>/g, "\n");
};

const copyText = async (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      showClipboardCheckIcon.value = true;
      setTimeout(() => {
        showClipboardCheckIcon.value = false;
      }, 3000);
    })
    .catch((error) => {
      console.log(error);
    });

  resultsStore.updateStream({ text_copied: true });
};

const props = defineProps({
  text: {
    type: String,
    default: "",
  },
});
</script>
