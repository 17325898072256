/* global LemonSqueezy */
/* global Paddle */
import { ref, inject } from "vue";
import { defineStore } from "pinia";
import { useRouter } from "vue-router";

import { useLemonStore } from "@/stores/payment/lemonStore";
import { usePaddleStore } from "@/stores/payment/paddleStore";
import { useAuthStore } from "@/stores/authStore";

export const usePaymentStore = defineStore("payment", () => {
  const tiers = ref([
    {
      name: "Basic",
      id: "tier-basic",
      originalPrice: "$0.9",
      discountedPrice: "$0.75",
      description: "3 Hours of Video/Audio Summarization",
      extendedDescription: "$0.25 per hour",
      features: [
        "No subscription",
        "No expiration date",
        "Summarize videos and audios from many websites",
        "Local video and audio file summarization",
      ],
      productId: process.env.VUE_APP_LEMON_3HOURS_PRODUCT_ID,
      featured: true,
      ctaText: "Buy plan",
    },
    {
      name: "Plus",
      id: "tier-plus",
      originalPrice: "$9",
      discountedPrice: "$4.80",
      description: "30 Hours of Video/Audio Summarization",
      extendedDescription: "$0.16 per hour",
      features: [
        "No subscription",
        "No expiration date",
        "Summarize videos and audios from many websites",
        "Local video and audio file summarization",
      ],
      productId: process.env.VUE_APP_LEMON_30HOURS_PRODUCT_ID,
      featured: false,
      ctaText: "Buy plan",
    },
  ]);
  const pricingMinimalUI = ref(false);
  const showPricingInProfile = ref(false);

  const setPricingMinimalUI = (minimalUI) => {
    pricingMinimalUI.value = minimalUI;
  };
  const setShowPricingInProfile = (pricingUI) => {
    showPricingInProfile.value = pricingUI;
  };

  const authStore = useAuthStore();
  const router = useRouter();
  const saEvent = inject("saEvent");

  async function waitForUserToBeAvailable() {
    return new Promise((resolve) => {
      const checkUser = () => {
        if (authStore.user?.email) {
          resolve();
        } else {
          setTimeout(checkUser, 100);
        }
      };
      checkUser();
    });
  }

  const lemonStore = useLemonStore();

  async function buyLemonSubscription(productId) {
    await lemonStore.loadLemon();
    await waitForUserToBeAvailable();
    window.createLemonSqueezy();
    const checkoutUrl = `https://nutshellpro.lemonsqueezy.com/checkout/buy/${productId}?checkout[email]=${authStore.user?.email}&checkout[custom][user_id]=${authStore.user?.id}&embed=1`;
    LemonSqueezy.Url.Open(checkoutUrl);
  }

  function handleBuyBottonClick(tierId) {
    // saEvent(`click_button_pricing_${tierId}`);
    const tier = tiers.value.find((t) => t.id === tierId);
    if (!tier) return;

    if (!authStore.user?.is_authenticated) {
      const currentTime = new Date().getTime();
      localStorage.setItem("lastAttemptToBuy", currentTime);
      router.push({ name: "Login" });
      return;
    }
    buyLemonSubscription(tier.productId);
  }

  const paddleStore = usePaddleStore();

  async function buyPaddleSubscription(productId) {
    await paddleStore.loadPaddle();
    Paddle.Checkout.open({
      product: productId,
      email: authStore.user?.email,
      disableLogout: true,
      customData: { user_id: authStore.user?.id },
      successCallback: (data) => {
        paddleStore.getMySubscriptionStatus();
      },
      closeCallback: (data) => {
        console.log(data);
        // display a failure alert
      },
    });
  }
  return {
    tiers,
    pricingMinimalUI,
    showPricingInProfile,
    setPricingMinimalUI,
    setShowPricingInProfile,
    buyLemonSubscription,
    handleBuyBottonClick,
  };
});
